import { useEffect } from 'react';

import useSystem from 'coreSrc/base/systemManager/useSystem';

import delay from 'coreSrc/core/js/utils/delay/delay';

import { setTag } from '@sentry/nextjs';

interface IPartnerProps {}

const Partner: React.FunctionComponent<IPartnerProps> = () => {
  const system = useSystem();

  useEffect(() => {
    init();

    async function init() {
      const partnerType = system.partner.getType();

      addSentryContext();
      logAnalytics();

      await delay(1000);

      function addSentryContext() {
        setTag('partner_type', partnerType || 'none');
      }

      function logAnalytics() {
        richgoAnalytics.log('partner', { type: partnerType });
      }
    }
  }, []);

  return null;
};

export default Partner;
